* {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1em;
    font-family: $font-family-default;
    font-weight: 300;
    line-height: 1.6;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
//strong,
b {
    font-weight: normal;
}

.page {
    font-size: 1.6rem;
}

.container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

@media all and (max-width: 1023px) {
    .container {
        max-width: 733px;
    }
}

@media all and (max-width: 767px) {
    .container {
        max-width: 95%;
    }
}
