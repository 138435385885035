.about {

    .about__body {
        p {
            margin-bottom: 1.6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.about--portrait {
        overflow-y: hidden;
        min-height: 680px;

        .icon-star {
            margin-bottom: 20px;
        }

        .section__title {
            margin-bottom: 40px;

            .title--first-line,
            .title--second-line,
            path,
            polygon {
                color: #D0021B;
                fill: #D0021B;
            }
        }

        .about__image {
            position: absolute;
            right: -130px;
            top: 70px;
        }
    }

    &.about--cv {
        background-color: #FAFAFA;
        padding-bottom: 80px;

        .title--first-line {
            color: $color-black;
        }

        .grid {
            justify-content: space-around;
        }

        ul {
            list-style: none none;

            span {
                display: inline-block;
                font-weight: bold;
                min-width: 50px;
            }
        }
    }

    &.about--skills {
        padding-bottom: 40px;

        .grid {
            justify-content: space-around;
        }

        .title--first-line {
            color: $color-black;
        }

        .about--skills__tools {
            list-style: none none;

            li {
                display: inline-block;
                text-align: center;
                margin-right: 2rem;
                margin-bottom: 2rem;
            }
        }

        .about--skills__programming {
            list-style: none none;

            .tool {
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                    flex-basis: 120px;
                }

                .bar {
                    flex: 1;
                    height: 6px;
                    background-color: #F2F2F2;
                    border-radius: 6px;

                    &:before {
                        content: '';
                        display: block;
                        border-radius: 6px;
                        height: 100%;
                        background-color: #4990E2;
                    }
                }

                &.tool--html { .bar:before { width: 95%; } }
                &.tool--css { .bar:before { width: 95%; } }
                &.tool--js { .bar:before { width: 75%; } }
                &.tool--php { .bar:before { width: 80%; } }
                &.tool--mysql { .bar:before { width: 60%; } }
                &.tool--rex { .bar:before { width: 95%; } }
                &.tool--wp { .bar:before { width: 60%; } }
                &.tool--gulp { .bar:before { width: 40%; } }
            }
        }
    }
}


@media all and (max-width: 1279px) {
    .about {
        &.about--portrait {
            .about__image {
                right: -186px;
            }
        }
    }
}


@media all and (max-width: 1023px) {
    .about {
        &.about--portrait {
            .section__title {
                margin-bottom: 10px;
                padding-top: 30px;

                h2 {
                    margin-left: -340px;
                }
            }

            .about__image {
                right: -176px;
                max-width: 720px;
                top: 190px;
            }
        }


        &.about--cv {
            padding-bottom: 50px;

            ul {
                li {
                    margin-bottom: 0.6rem;
                }
                span {
                    display: block;
                }
            }
        }
    }
}



@media all and (max-width: 767px) {
    .about {
        &.about--portrait {
            .section__title {
                h2 {
                    margin-left: 0;
                }
            }

            .about__image {
                position: static;
                max-width: 100%;
            }
        }

    }
}
