
#projects {
    border-top: 1px solid #EFEFEF;
}



.project {
    padding: 80px 0;

    .project__title {
        h4 {
            font-size: $font-size-title-project;
            font-family: $font-family-project;
            letter-spacing: -8px;
            line-height: 1.1;
            margin-top: 10px;
        }
    }

    .project__body {
        p {
            margin-bottom: 1.6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.project--swisscom {

        background-image: url(../images/bg-project-tvair.jpg);

        h4 {
            text-indent: -25px;

        }

        .project__image {
            position: absolute;
            top: 0;
            left: -18px;
        }
    }

    &.project--bluewin {
        background-image: url(../images/img-project-bluewin.jpg), linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.03) 100%);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;

        h4 {
            text-indent: -80px;
        }
    }

    &.project--billnet {

        background-color: rgba(45,110,112,0.90);
        color: $color-white;

        h4 {
            text-indent: -80px;
        }

        .project__image {
            position: absolute;
            bottom: -80px;
            left: 60px;
        }
    }

    &.project--helvetica {
        min-height: 830px;

        h4 {
            text-indent: -40px;
        }

        .project__image {
            position: absolute;
            left: -100px;
            top: -30px;
        }
    }

    &.project--mwatch {
        background-image: url(../images/bg-project-mwatch.jpg), url(../images/bg-project-mwatch_repeater.jpg);
        background-repeat: no-repeat, repeat-x;
        background-position: top center, top center;
        min-height: 850px;

        h4 {
            text-indent: -80px;
            color: $color-white;
            margin-top: 0;
            margin-bottom: 150px
        }

        .project__image {
            position: absolute;
            top: -56px;
            left: 0px;
        }
    }

    &.project--luminox {
        background-image: url(../images/bg-project-luminox.jpg);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        color: $color-white;
        min-height: 800px;

        h4 {
            text-indent: -70px;
            margin-top: -50px;
        }

        .project__image {
            position: absolute;
            top: -36px;
            left: 70px;
        }
    }
}


@media all and (max-width: 1279px) {
    #projects {
        .container {
            max-width: 820px;
        }
    }

    .project {
        padding: 50px 0;

        &.project--swisscom {

            .project__image {
                max-width: 640px;
            }
        }

        &.project--bluewin {
        }

        &.project--billnet {
            .project__image {
                left: 80px;
                bottom: -50px;
                max-width: 520px;
            }
        }

        &.project--helvetica {
            min-height: 700px;

            .project__image {
                left: -60px;
                top: -1px;
                max-width: 530px;
            }
        }

        &.project--mwatch {
            background-position: center -82px, center -82px;
            min-height: 680px;

            h4 {
                margin-bottom: 90px;
            }

            .project__image {
                top: -16px;
                max-width: 380px;
            }
        }

        &.project--luminox {
            min-height: 680px;

            .project__image {
                top: 0;
                left: 42px;
                max-width: 600px;
            }
        }
    }
}


@media all and (max-width: 1023px) {

    #projects {
        .container {
            max-width: 706px;
        }
    }

    .project {
        padding: 30px 0;

        .project__title {
            h4 {
                font-size: 15rem;
            }
        }

        &.project--swisscom {

            h4 {
                text-indent: -15px;
            }

            .project__image {
                max-width: 530px;
                top: 27px;
                left: -18px;
            }
        }

        &.project--bluewin {
            h4 {
                padding-top: 0;
                text-indent: -22px;
            }
        }

        &.project--billnet {
            h4 {
                text-indent: -20px;
            }

            .project__image {
                left: -10px;
                bottom: -30px;
                max-width: 450px;
            }
        }

        &.project--helvetica {
            min-height: 610px;

            .project__image {
                left: -34px;
                max-width: 480px;
            }
        }

        &.project--mwatch {
            background-position: center -140px, center -140px;
            min-height: 570px;

            h4 {
                text-indent: -20px;
                margin-bottom: 104px;
            }

            .project__image {
                left: -45px;
                max-width: 340px;
            }
        }

        &.project--luminox {
            min-height: 570px;

            .project__title {
                svg {
                    margin-left: 25px;
                }
            }

            h4 {
                text-indent: -20px;
                margin-top: -40px;
            }

            .project__image {
                left: 0;
                max-width: 520px;
            }
        }
    }
}


@media all and (max-width: 767px) {
    .project {
        min-height: auto !important;

        h4 {
            text-indent: 0 !important;
        }
        .project__image {
            position: static !important;
            max-width: 100% !important;
        }

        &.project--bluewin {
            background-position: -370px bottom, center center;
            background-size: 250% auto, auto 100%;
            min-height: 720px !important;
        }

        &.project--mwatch {
            background-position: center -210px, center -210px;

            h4 {
                margin-bottom: 44px;
            }
        }

        &.project--luminox {

            .project__title {
                svg {
                    margin-left: 45px;
                }
            }
        }
    }

}
