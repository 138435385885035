#competences {
    padding-bottom: 80px;
}

.competence {
    h3 {
        font-size: 5.4rem;
        transform: rotate(-9deg);
    }

    .title__line svg {
        max-width: 70px;
    }

    .icon {
        margin: 20px 0;
    }

    .title__competence {
        margin-bottom: 40px;
    }

    .competence__body {
        min-height: 230px;
    }

    .competence__footer {
        font-weight: normal;

        &:before {
            content: " ";
            border-bottom: 2px solid $color-black;
            width: 50px;
            display: block;
            margin: 32px 0;
        }
    }

    &.competence--strategy {
        h3,
        .title__line path {
            color: $color-blue;
            fill: $color-blue;
        }
    }

    &.competence--design {
        h3,
        .title__line path {
            color: $color-yellow;
            fill: $color-yellow;
        }
    }

    &.competence--technology {
        h3,
        .title__line path {
            color: $color-antracite;
            fill: $color-antracite;
        }
    }
}

@media all and (max-width: 1023px) {
    .competence {
        .competence__body {
            min-height: 300px;
        }
    }
}

@media all and (max-width: 767px) {

    #competences {
        padding-bottom: 50px;
    }

    .competence {
        .competence__body {
            min-height: auto;
        }
    }
}
