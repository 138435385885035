
.page__header {
    height: 90vh;
    background-image: url(../images/img-vouillamoz-intro.png), $color-gradient;
    //background-blend-mode: multiply;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto 75%, 100%;
    min-height: 700px;
    position: relative;
}

.page__logo {
    position: absolute;
    left: 35px;
    top: 25px;
}

.page__header__contact {
    font-size: $font-size-small;
    font-weight: 300;
    position: absolute;
    right: 35px;
    top: 25px;

    a {
        color: $color-white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.title {
    text-align: center;
    font-weight: normal;
    font-family: $font-family-title;
    line-height: 0.8;

    .title--first-line {
        font-size: $font-size-title-first-line;
        display: inline-block;
    }

    .title--second-line {
        font-size: $font-size-title-second-line;
        display: inline-block;
    }
}

.page__title {
    color: $color-white;
    padding-top: 40px;

    .icon-star {
        padding: 16px 0;
    }

    .title--first-line {
        transform: rotate(-7deg);
    }

    .title--second-line {
        transform: rotate(-12deg);
    }

    .title__line {
        path {
            fill: $color-white;
        }
    }
}


.section__title {
    padding-top: 80px;

    h2 {
        padding-bottom: 40px;
    }

    h3 {
        margin-bottom: 40px;

        span {
            line-height: 0.6;
        }
    }

    .title--first-line {
        color: $color-antracite;
        transform: rotate(-7deg);
    }

    .title--second-line {
        color: $color-black;
        transform: rotate(-9deg);
    }

    .title__line {
        path {
            fill: $color-black;
        }
    }

    .title__text {
        //padding-top: 40px;
        font-family: $font-family-default;
        font-size: $font-size-teaser;
        max-width: 612px;
        margin: 0 auto 60px auto;
        line-height: 1.4;
        font-weight: 300;
    }
}


.page__subline {
    position: absolute;
    bottom: 25px;
    text-align: center;
    width: 100%;
    color: $color-white;
    font-size: $font-size-default;
}


.page__footer {
    background-image: $color-gradient;
    color: $color-white;
    padding: 40px 0;

    .grid {
        justify-content: space-around;
    }

    .about__container--right {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        p { text-align: left; }
    }

    a {
        color: $color-white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}


@media all and (max-width: 767px) {

    .page__header {
        min-height: auto;
        height: 80vh;
    }

    .page__logo,
    .page__header__contact {
        display: none;
    }

    .page__title {
        padding-top: 20px;
    }

    .title {
        .title--first-line {
            font-size: 5.1rem;
        }

        .title--second-line {
            font-size: 7.1rem;
        }
    }

    .section__title {
        padding-top: 50px;

        h3 {
            margin-bottom: 20px;
        }

        .title__text {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .page__footer {
        .about__container--right {
            align-items: flex-start;
        }
    }
}
